import { Controller } from "@hotwired/stimulus"
import Swiper from "swiper"
import { Pagination } from "swiper/modules"

export default class extends Controller {
	static targets = ["slider", "pagination"]

	connect() {
		this.slider = new Swiper(this.sliderTarget, {
			loop: true,
			modules: [ Pagination ],
			pagination: {
				el: this.paginationTarget,
				clickable: true,
				renderBullet: (index, className) => {
					return `<div class="${className}"><div class="swiper-pagination-bullet__inner-bullet"></div></div>`
				},
			},
		})
	}
}
