import "@hotwired/turbo-rails"
import "./controllers"

import "trix"
import "@rails/actiontext"

Trix.config.blockAttributes.heading1.tagName = "h3"

addEventListener("trix-initialize", function() {
	const file_tools = document.querySelector(".trix-button-group--file-tools")
	file_tools.remove()
})
addEventListener("trix-file-accept", function(e) {
	e.preventDefault()
})
