import { Controller } from "@hotwired/stimulus"
import { debounce } from "../../helpers"

export default class extends Controller {
	static targets = ["video"]

	static values = {
		url: String,
		squaredUrl: String
	}

	connect() {
		this.debouncedUpdate = debounce(this.debouncedUpdate.bind(this), 1000)

		if (window.innerWidth < 1024) {
			this.videoTarget.src = this.squaredUrlValue
		} else {
			this.videoTarget.src = this.urlValue
		}
	}

	debouncedUpdate() {
		if (window.innerWidth < 1024) {
			this.videoTarget.src = this.squaredUrlValue
		} else {
			this.videoTarget.src = this.urlValue
		}
	}
}
