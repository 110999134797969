import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["service", "filter"]

	static classes = ["filterActive", "filterInactive"]

	static values = {
		activeKey: String
	}

	filter({ params }) {
		this.activeKeyValue = params.key
	}

	activeKeyValueChanged() {
		this.filterTargets.forEach(filter => {
			this.deactivateFilter(filter)

			if (filter.dataset.servicesKeyParam == this.activeKeyValue) {
				this.activateFilter(filter)
			}
		})

		this.serviceTargets.forEach(service => {
			service.setAttribute("disabled", true)

			const keys = JSON.parse(service.dataset.keys)
			if (this.activeKeyValue == "" || keys.includes(this.activeKeyValue)) {
				service.setAttribute("disabled", false)
			}
		})
	}

	activateFilter(filter) {
		this.filterInactiveClasses.forEach(className => {
			filter.classList.remove(className)
		})

		this.filterActiveClasses.forEach(className => {
			filter.classList.add(className)
		})
	}

	deactivateFilter(filter) {
		this.filterActiveClasses.forEach(className => {
			filter.classList.remove(className)
		})

		this.filterInactiveClasses.forEach(className => {
			filter.classList.add(className)
		})
	}

	activateService(service) {
		this.serviceInactiveClasses.forEach(className => {
			service.classList.remove(className)
		})
	}
}
