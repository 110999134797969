import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["input", "preview"]

	connect() {
		this.previewTarget.style.backgroundColor = this.inputTarget.value
	}

	update() {
		this.previewTarget.style.backgroundColor = this.inputTarget.value
	}
}
