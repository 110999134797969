import { Controller } from "@hotwired/stimulus";
import { animate, timeline, stagger, inView } from "motion";

export default class extends Controller {
	static targets = ["background"];

	connect() {
		inView(
			this.backgroundTarget,
			(enterInfo) => {
				timeline([
					[
						".work-streams-alt .voronoi-pattern path",
						{ fill: "rgb(51 53 63)", opacity: [null, 0.05] },
						{ duration: 1, delay: stagger(0.075), easing: [0.76, 0, 0.24, 1] },
					],
				]);
			},
			{ amount: 1 },
		);

		inView(
			".work-streams-alt article",
			(enterInfo) => {
				timeline([
					[
						enterInfo.target.querySelector(".work-streams__link"),
						{ opacity: [0, 1], y: [60, 0] },
						{ duration: 1, easing: [0.76, 0, 0.24, 1] },
					],
					[
						enterInfo.target.querySelector(".work-streams__label"),
						{ opacity: [0, 1], x: [100, 0] },
						{ duration: 1, at: "<", easing: [0.76, 0, 0.24, 1] },
					],
					[
						enterInfo.target.querySelector(".work-streams__content"),
						{ opacity: [0, 1], x: [100, 0] },
						{ duration: 1, at: "<", easing: [0.76, 0, 0.24, 1] },
					],
				]);
			},
			{ amount: 1 },
		);
	}

	highlight({ currentTarget, params }) {
		const paths = this.randomPaths;

		animate(
			paths.slice(0, paths.length / 2),
			{ fill: `rgb(${params.color})`, opacity: 0.3 },
			{ duration: 0.75 },
		);
		animate(
			paths.slice(paths.length / 2, paths.length - 1),
			{ fill: `rgb(${params.color})`, opacity: 0.6 },
			{ duration: 0.75 },
		);
	}

	removeHighlight() {
		animate(
			this.backgroundTarget.querySelectorAll(".voronoi-pattern path"),
			{ fill: "rgb(51 53 63)", opacity: 0.05 },
			{ duration: 0.75 },
		);
	}

	get randomPaths() {
		const paths = Array.from(
			this.backgroundTarget.querySelectorAll(".voronoi-pattern path"),
		).sort(() => 0.5 - Math.random());
		const min = (paths.length * 1) / 3;
		const max = (paths.length * 2) / 3;

		return paths.slice(0, Math.floor(Math.random() * (max - min + 1)) + min);
	}
}
