import { Controller } from "@hotwired/stimulus"
import Swiper from "swiper"
import { Autoplay } from "swiper/modules"

export default class extends Controller {
	static values = {
		duration: {
			type: Number,
			default: 80000
		},
		gap: {
			type: Number,
			default: 100
		}
	}

	connect() {
		this.slider = new Swiper(this.element, {
			modules: [ Autoplay ],
			autoplay: {
				delay: 1,
				disableOnInteraction: false,
				pauseOnMouseEnter: false,
			},
			freeMode: true,
			slidesPerView: "auto",
			speed: this.durationValue,
			spaceBetween: this.gapValue,
			resistance: true,
			resistanceRatio: 0,
			loop: true,
			allowTouchMove: false,
		})
	}
}
