import { Controller } from "@hotwired/stimulus"
import { animate, timeline, stagger } from "motion"

export default class extends Controller {
	static targets = ["menu"]

	show() {
		window.scrollTo(0, 0)

		document.body.style.overflow = "hidden"
		this.menuTarget.style.display = "block"
		timeline([
			[this.menuTarget, { opacity: [0, 1] }, { duration: 0.3 }],
			[".menu .voronoi-pattern path", { opacity: [0, 0.05] }, { duration: 1, delay: stagger(0.075), easing: [0.76, 0, 0.24, 1], at: "<" }],
			[".menu .menu__link", { opacity: [0, 1], y: [60, 0] }, { duration: 1, delay: stagger(0.15), at: "-1.75", easing: [0.76, 0, 0.24, 1] }],
			[".menu .menu__label", { opacity: [0, 1], x: [100, 0] }, { duration: 1, delay: stagger(0.15), at: "<", easing: [0.76, 0, 0.24, 1] }],
			[".menu .menu__logo", { opacity: [0, 1] }, { duration: 1, delay: stagger(0.15), at: "-1", easing: [0.76, 0, 0.24, 1] }],
		])
	}

	hide() {
		timeline([
			[this.menuTarget, { opacity: [1, 0] }, { duration: 0.5 }],
			[this.menuTarget, { display: "none" }, { duration: null }]
		])
		document.body.style.overflow = "visible"
	}

	highlight({ currentTarget, params }) {
		const paths = this.randomPaths

		animate(paths.slice(0, paths.length / 2), { fill: `rgb(${params.color})`, opacity: 0.3 }, { duration: 0.75 })
		animate(paths.slice(paths.length / 2, paths.length - 1), { fill: `rgb(${params.color})`, opacity: 0.6 }, { duration: 0.75 })
	}

	removeHighlight() {
		animate(this.menuTarget.querySelectorAll(".voronoi-pattern path"), { fill: "rgb(51 53 63)", opacity: 0.05 }, { duration: 0.75 })
	}

	get randomPaths() {
		const paths = Array.from(this.menuTarget.querySelectorAll(".voronoi-pattern path")).sort(() => 0.5 - Math.random())
		const min = paths.length * 1/3
		const max = paths.length * 2/3

		return paths.slice(0, Math.floor(Math.random() * (max - min + 1)) + min)
	}
}
