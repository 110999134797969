import { Controller } from "@hotwired/stimulus"
import Swiper from "swiper"
import { Navigation } from "swiper/modules"

export default class extends Controller {
	static targets = ["slider", "prevButton", "nextButton", "counter"]

	connect() {
		this.slider = new Swiper(this.sliderTarget, {
			modules: [ Navigation ],
			loop: true,
			centeredSlides: true,
			navigation: {
				nextEl: this.nextButtonTarget,
				prevEl: this.prevButtonTarget
			},
			breakpoints: {
				768: {
					slidesPerView: 2,
					spaceBetween: 48
				},
				1280: {
					slidesPerView: 3.5,
					spaceBetween: 48,
				}
			},
			on: {
				slideChange: () => {
					this.counterTarget.innerText = `${this.slider?.realIndex + 1} / ${this.slider?.slides.length}`
				},
			}
		})

		this.counterTarget.innerText = `${this.slider?.realIndex + 1} / ${this.slider?.slides.length}`
	}
}
