import { Controller } from "@hotwired/stimulus"
import { animate } from "motion"
import { interpolate } from "flubber"

export default class extends Controller {
	static targets = ["path"]

	static values = {
		paths: Array
	}

	connect() {
		this.animation()
	}

	disconnect() {
		this.disconnected = true
	}

	async animation() {
		let mixPaths, animation

		if (this.disconnected) return

		mixPaths = interpolate(this.pathsValue[0], this.pathsValue[1], { maxSegmentLength: 10 })
		animation = animate(progress => this.pathTarget.setAttribute("d", mixPaths(progress)), { duration: 5 })
		await animation.finished

		mixPaths = interpolate(this.pathsValue[1], this.pathsValue[2], { maxSegmentLength: 10 })
		animation = animate(progress => this.pathTarget.setAttribute("d", mixPaths(progress)), { duration: 5 })
		await animation.finished

		mixPaths = interpolate(this.pathsValue[2], this.pathsValue[3], { maxSegmentLength: 10 })
		animation = animate(progress => this.pathTarget.setAttribute("d", mixPaths(progress)), { duration: 5 })
		await animation.finished

		mixPaths = interpolate(this.pathsValue[3], this.pathsValue[0], { maxSegmentLength: 10 })
		animation = animate(progress => this.pathTarget.setAttribute("d", mixPaths(progress)), { duration: 5 })
		await animation.finished

		this.animation()
	}
}
