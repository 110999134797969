import { Controller } from "@hotwired/stimulus"
import Swiper from "swiper"
import { Pagination, Autoplay } from "swiper/modules"

export default class extends Controller {
	static targets = ["slider", "pagination"]

	static values = {
		autoplay: {
			type: Boolean,
			default: true
		}
	}

	connect() {
		this.slider = new Swiper(this.sliderTarget, {
			modules: [ Pagination, Autoplay ],
			loop: true,
			grabCursor: true,
			speed: 1000,
			pagination: {
				el: this.paginationTarget,
				clickable: true,
				renderBullet: (index, className) => {
					return `<div class="${className}"><div class="swiper-pagination-bullet__inner-bullet"></div></div>`
				},
			},
			autoplay: this.autoplayValue ? {
				delay: 6000,
				disableOnInteraction: true,
				pauseOnMouseEnter: true
			} : false
		})
	}
}
